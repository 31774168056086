import React from "react";
import Header from "../../Common/Header/Header";
import Sidebar from "../../Common/Sidebar/Sidebar";
import {Alert, Button, Card, Form, Modal, Spinner} from "react-bootstrap";
import i18next from "i18next";
import DataTable from "react-data-table-component";
import {PostData} from "../../api_key/PostData";
import Autocomplete from "react-google-autocomplete";

class Cities extends React.Component {
    constructor(props) {
        super(props);
        let permissionKey = "manage_cities"
        const currentPermission = props.permissionList.find(permission => permission.permission_key === permissionKey);
        const permissionKeysList = props.permissionList.map(permission => permission.permission_key);
        this.state = {
            granularPermissions:currentPermission.granular_types,
            permissionKeysList:permissionKeysList,
            sessionData: JSON.parse(localStorage.getItem('loginDetails')),
            dataLoader: true,
            newCitiesAlert: {show: false, variant: "", message: ""},
            editCitiesForm: false,
            addNewCitiesModal: false,
            cityUuid: "",
            cityName: "",
            cityNameErr: "",
            zipcode: "",
            zipcodeErr: "",
            contactUsEmail: "",
            contactUsEmailErr: "",
            contactUsPhone: "",
            contactUsPhoneErr: "",
            inquiryEmail: "",
            inquiryEmailErr: "",
            latitude: "",
            longitude: "",
            handleDeletePopup: false,
            inlinePreloader: false,
        }
        document.title = this.props.pageTitle
        this.handleClose = this.handleClose.bind(this)
        this.handleDeleteCities = this.handleDeleteCities.bind(this)
        this.addNewCities = this.addNewCities.bind(this)
    }

    componentDidMount() {
        this.props.loadFooterHeight()
        setTimeout(() => {
            this.setState({dataLoader: false})
        }, 3000);

        this.loadAllCities();
    }

    loadAllCities() {
        PostData("/frontCall/cities/getAll")
            .then(result => {
                if(result.status === true) {
                    this.setState({
                        citiesData: result.response,
                        columns: [
                            {
                                name: <h6 className="font-weight-bold">{i18next.t("city_name")}</h6>,
                                cell: (row) => row.name,
                                sortable: true
                            },
                            {
                                name: <h6 className="font-weight-bold">{i18next.t("zipcode")}</h6>,
                                cell: (row) => row.zipcode,
                                sortable: true
                            },
                            {
                                name: <h6 className="font-weight-bold">{i18next.t("contact_us_email")}</h6>,
                                cell: (row) => row.contact_us_email,
                                sortable: true
                            },
                            {
                                name: <h6 className="font-weight-bold">{i18next.t("contact_us_phone")}</h6>,
                                cell: (row) => row.contact_us_phone,
                                sortable: true
                            },
                            {
                                name: <h6 className="font-weight-bold">{i18next.t("inquiry_email")}</h6>,
                                cell: (row) => row.inquiry_email,
                                sortable: true
                            },
                            ...(this.state.granularPermissions.includes('u')) ? [{
                                name: <h6 className="font-weight-bold">{i18next.t("edit")}</h6>,
                                cell: (row) => <Button variant="primary" className="my-2" onClick={() => {
                                    this.setState({
                                        editCitiesForm: true,
                                        addNewCitiesModal: true,
                                        cityUuid: row.uuid,
                                        cityName: row.name,
                                        zipcode: row.zipcode,
                                        contactUsEmail: row.contact_us_email,
                                        contactUsPhone: row.contact_us_phone,
                                        inquiryEmail: row.inquiry_email,
                                        latitude: row.latitude,
                                        longitude: row.longitude,
                                    })
                                }}><em className={"flaticon-381-edit"}/></Button>,
                                center: true
                            }] : [],
                            ...(this.state.granularPermissions.includes('d')) ? [{
                                name: <h6 className="font-weight-bold">{i18next.t("delete")}</h6>,
                                cell: (row) => <Button variant="danger" className="my-2" onClick={() => {
                                    this.setState({handleDeletePopup: true, cityUuid: row.uuid})
                                }}><em className="flaticon-381-trash-1"/></Button>,
                                center: true
                            }] : []
                        ],
                    })
                }
            })
    }

    addNewCities() {
        this.setState({inlinePreloader: true})
        if (this.state.cityName === "") {
            this.setState({cityNameErr: i18next.t("enter_city")})
        } else {
            this.setState({cityNameErr: ""})
        }
        if (this.state.zipcode === "") {
            this.setState({zipcodeErr: i18next.t("enter_zipcode")})
        } else {
            this.setState({zipcodeErr: ""})
        }
        if (this.state.contactUsEmail === "") {
            this.setState({contactUsEmailErr: i18next.t("enter_contact_us_email")})
        } else {
            this.setState({contactUsEmailErr: ""})
        }
        if (this.state.contactUsPhone === "") {
            this.setState({contactUsPhoneErr: i18next.t("enter_contact_us_phone")})
        } else {
            this.setState({contactUsPhoneErr: ""})
        }
        if (this.state.inquiryEmail === "") {
            this.setState({inquiryEmailErr: i18next.t("enter_inquiry_email")})
        } else {
            this.setState({inquiryEmailErr: ""})
        }

        let formData = {
            name: this.state.cityName,
            zipcode: this.state.zipcode,
            contact_us_email: this.state.contactUsEmail,
            contact_us_phone: this.state.contactUsPhone,
            inquiry_email: this.state.inquiryEmail,
            latitude: this.state.latitude,
            longitude: this.state.longitude,
        }
        if (this.state.editCitiesForm) { formData.uuid = this.state.cityUuid }

        if (formData.name !== "" && formData.zipcode !== "" && formData.contact_us_email !== ""
            && formData.contact_us_phone !== "" && formData.inquiry_email !== "") {
            let postApi = this.state.editCitiesForm ? "/frontCall/cities/updateCity" : "/frontCall/cities/add";
            PostData(postApi, formData)
                .then(result => {
                    if (result.status === true) {
                        this.setState({
                            newCitiesAlert: {
                                show: true, variant: "success",
                                message: this.state.editCitiesForm ? i18next.t("city_updated_successfully") : i18next.t("city_added_successfully")
                            },
                        })
                        this.loadAllCities()
                        setTimeout(() => {
                            this.handleClose()
                        }, 2000);
                    } else {
                        this.setState({
                            newCitiesAlert: {show: true, variant: "danger", message: result.messages.message},
                            inlinePreloader: false,
                        })
                    }
                })
        } else {
            this.setState({inlinePreloader: false})
        }
    }

    handleDeleteCities() {
        this.setState({inlinePreloader: true})
        PostData("/frontCall/cities/deleteCity", {uuid: this.state.cityUuid})
            .then(result => {
                if (result.status === true) {
                    this.setState({
                        newCitiesAlert: {show: true, variant: "success", message: i18next.t("city_deleted_successfully")},
                    })
                    setTimeout(() => {
                        this.handleClose()
                    }, 2000);
                    this.loadAllCities()
                }
            })
    }

    onChangeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    handleClose() {
        this.setState({
            newCitiesAlert: {show: false, variant: "", message: ""},
            editCitiesForm: false,
            addNewCitiesModal: false,
            cityUuid: "",
            cityName: "",
            cityNameErr: "",
            zipcode: "",
            zipcodeErr: "",
            contactUsEmail: "",
            contactUsEmailErr: "",
            contactUsPhone: "",
            contactUsPhoneErr: "",
            inquiryEmail: "",
            inquiryEmailErr: "",
            latitude: "",
            longitude: "",
            handleDeletePopup: false,
            inlinePreloader: false,
        })
    }

    render() {
        return (
            <>
                {this.state.dataLoader &&
                    <div className="api-data-loader">
                        <Spinner animation="grow" variant="info"/>
                    </div>}

                <Header headerTitlePage={this.props.headerTitle} logoutUser={this.props.userLogout}/>
                <Sidebar permissionList={this.props.permissionList}/>
                <div className="content-body" id="content-body">
                    <div className="container-fluid">
                        {(this.state.granularPermissions.includes('c')) && <div className="text-right mb-3">
                            <Button variant={"primary"} className="mb-2" onClick={() => {
                                this.setState({addNewCitiesModal: true})
                            }}>{i18next.t("add_new_city")}</Button>
                        </div>}
                        <Modal show={this.state.addNewCitiesModal} onHide={this.handleClose}
                               centered backdrop="static" keyboard={false}>
                            <Modal.Header className="modal-header">
                                <Modal.Title>{this.state.editCitiesForm ? i18next.t("edit_city") : i18next.t("add_new_city")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Alert show={this.state.newCitiesAlert.show} variant={this.state.newCitiesAlert.variant} className="rounded-lg">
                                    <h6 className="m-0 font-weight-bold">{this.state.newCitiesAlert.message}</h6>
                                </Alert>
                                <Form>
                                    <Form.Group className="mb-3" controlId="cityName">
                                        <Form.Label>{i18next.t("city_name")}</Form.Label>
                                        <Autocomplete
                                            apiKey={"AIzaSyBG7TCobbomGm8tGu01XFKtBGVJzHRruRQ"}
                                            onPlaceSelected={(place, inputRef, autocomplete) => {
                                                let selectedPlace = []
                                                if(place.address_components.filter(data => data.types.indexOf("postal_code") > -1 && data).length > 0) {
                                                    selectedPlace = place.address_components.filter(data => data.types.indexOf("postal_code") > -1 && data)
                                                    selectedPlace = selectedPlace[0].long_name
                                                }
                                                this.setState({
                                                    cityName: place.formatted_address.split(',')[0],
                                                    latitude: place.geometry.location.lat(),
                                                    longitude: place.geometry.location.lng(),
                                                    zipcode: selectedPlace,
                                                })
                                            }}
                                            language={i18next.language}
                                            options={{
                                                types: ["(cities)"],
                                                componentRestrictions: { country: ["ru","in"] },
                                            }}
                                            className={"form-control"}
                                            name="cityName" id="cityName"
                                            value={this.state.cityName}
                                            onChange={this.onChangeInput}
                                            placeholder={i18next.t("start_typing") + "..."}
                                        />
                                        {this.state.cityNameErr &&
                                            <div className="text-danger pl-3 pt-1">{this.state.cityNameErr}</div>}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="zipcode">
                                        <Form.Label>{i18next.t("enter_zipcode")}</Form.Label>
                                        <Form.Control type="text" name="zipcode"
                                                      value={this.state.zipcode}
                                                      onChange={this.onChangeInput}
                                                      placeholder={i18next.t("enter_zipcode")}/>
                                        {this.state.zipcodeErr &&
                                            <div className="text-danger pl-3 pt-1">{this.state.zipcodeErr}</div>}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="contactUsEmail">
                                        <Form.Label>{i18next.t("contact_us_email")}</Form.Label>
                                        <Form.Control type="text" name="contactUsEmail"
                                                      value={this.state.contactUsEmail}
                                                      onChange={this.onChangeInput}
                                                      placeholder={i18next.t("contact_us_email")}/>
                                        {this.state.contactUsEmailErr &&
                                            <div className="text-danger pl-3 pt-1">{this.state.contactUsEmailErr}</div>}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="contactUsPhone">
                                        <Form.Label>{i18next.t("contact_us_phone")}</Form.Label>
                                        <Form.Control type="text" name="contactUsPhone"
                                                      value={this.state.contactUsPhone}
                                                      onChange={this.onChangeInput}
                                                      placeholder={i18next.t("contact_us_phone")}/>
                                        {this.state.contactUsPhoneErr &&
                                            <div className="text-danger pl-3 pt-1">{this.state.contactUsPhoneErr}</div>}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="inquiryEmail">
                                        <Form.Label>{i18next.t("inquiry_email")}</Form.Label>
                                        <Form.Control type="text" name="inquiryEmail"
                                                      value={this.state.inquiryEmail}
                                                      onChange={this.onChangeInput}
                                                      placeholder={i18next.t("inquiry_email")}/>
                                        {this.state.inquiryEmailErr &&
                                            <div className="text-danger pl-3 pt-1">{this.state.inquiryEmailErr}</div>}
                                    </Form.Group>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant={"danger"} onClick={this.handleClose}>{i18next.t("close")}</Button>
                                <Button variant={"primary"} disabled={this.state.inlinePreloader} onClick={() => {
                                    this.addNewCities()
                                }}>{this.state.inlinePreloader
                                    ? <Spinner animation="grow" variant="info" className={"d-block m-0"}/>
                                    : i18next.t("save_changes")}</Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={this.state.handleDeletePopup} onHide={this.handleClose}
                               centered backdrop="static" keyboard={false}>
                            <Modal.Header className="justify-content-center align-items-center flex-column">
                                <Alert show={this.state.newCitiesAlert.show} variant={this.state.newCitiesAlert.variant} className="rounded-lg w-100">
                                    <h6 className="m-0 font-weight-bold">{this.state.newCitiesAlert.message}</h6>
                                </Alert>
                                <Modal.Title>{i18next.t("sure_to_delete_cities")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Footer className="justify-content-center">
                                <Button variant={"primary"} disabled={this.state.inlinePreloader} onClick={() => {
                                    this.handleDeleteCities()
                                }}>{this.state.inlinePreloader
                                    ? <Spinner animation="grow" variant="info" className={"d-block m-0"}/>
                                    : i18next.t("yes")}</Button>
                                <Button variant={"danger"} onClick={this.handleClose}>{i18next.t("no")}</Button>
                            </Modal.Footer>
                        </Modal>
                        <Card>
                            <Card.Body>
                                {(this.state.granularPermissions.includes('r'))
                                    && <DataTable
                                        columns={this.state.columns}
                                        data={this.state.citiesData}
                                        noDataComponent={i18next.t("no_records_to_display")}
                                    />}
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </>
        )
    }
}

export default Cities;